/** Overrides for Material table **/

table.mat-mdc-table {
  border-collapse: separate;
  tr:nth-child(2n):not(th)[role="row"] {
    /* every other row */
    background-color: var(--ef-table-alternating-color);
  }
  tr th[role="columnheader"] {
    background: rgb(214, 213, 213);
    background: linear-gradient(
      180deg,
      var(--ef-table-header-gradient-start) 0%,
      var(--ef-table-header-gradient-end) 100%
    );
  }
}

table.mat-mdc-table thead {
  tr[role="row"] {
    height: auto;
    th {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  tr th[role="columnheader"] {
    border-top-color: var(--ef-table-border-color);
    border-top-width: var(--ef-table-border-width);
    border-top-style: solid;
    border-bottom-color: var(--ef-table-border-color);
    border-bottom-width: var(--ef-table-border-width);
  }
  tr th:nth-child(1)[role="columnheader"] {
    border-top-left-radius: var(--ef-table-corner-radius);
    border-left-color: var(--ef-table-border-color);
    border-left-width: var(--ef-table-border-width);
    border-left-style: solid;
  }
  tr th:last-child[role="columnheader"] {
    border-top-right-radius: var(--ef-table-corner-radius);
    border-right-color: var(--ef-table-border-color);
    border-right-width: var(--ef-table-border-width);
    border-right-style: solid;
  }
}

table.mat-mdc-table tbody {
  tr td:last-child[role="cell"] {
    border-right-color: var(--ef-table-border-color);
    border-right-width: var(--ef-table-border-width);
    border-right-style: solid;
  }
  tr td:nth-child(1)[role="cell"] {
    border-left-color: var(--ef-table-border-color);
    border-left-width: var(--ef-table-border-width);
    border-left-style: solid;
  }
  tr:last-child[role="row"] {
    td[role="cell"] {
      border-bottom-color: var(--ef-table-border-color);
      border-bottom-width: var(--ef-table-border-width);
      border-bottom-style: solid;
      box-sizing: content-box;
    }

    td:nth-child(1)[role="cell"] {
      border-bottom-left-radius: var(--ef-table-corner-radius);
    }
    td:last-child[role="cell"] {
      border-bottom-right-radius: var(--ef-table-corner-radius);
    }
  }
  tr:hover[role="row"] {
    background: var(--ef-table-hover-row-color);
  }
}
table.mat-mdc-table tfoot[role="rowgroup"] {
  tr td {
    border-collapse: collapse;
    height: auto !important;
  }
  tr {
    height: auto !important;
  }
}
