@import "./generate-colors";

/* primary palette */

$ef-primary-old-palette: (
  50: #e0f4fd,
  100: #b1e2f9,
  200: #7ecff6,
  300: #4cbcf1,
  400: #26aeee,
  500: #00a0eb,
  600: #0092dc,
  700: #0080c8,
  800: #006fb4,
  900: #004f92,
  A100: #b1e2f9,
  A200: #7ecff6,
  A400: #26aeee,
  A700: #0080c8,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

/* accenmt palette */

$ef-accent-old-palette: (
  50: #f3f9e8,
  100: #e1efc6,
  200: #cde5a1,
  300: #b9da7b,
  400: #a8d25b,
  500: #98ca3c,
  600: #88ba34,
  700: #73a62a,
  800: #5e9220,
  900: #39700c,
  A100: #e1efc6,
  A200: #cde5a1,
  A400: #a8d25b,
  A700: #73a62a,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

/* grey palette */

$ef-grey-palette: (
  50: #fff,
  100: #f5f5f5,
  200: #ebebeb,
  300: #ddd,
  400: #ccc,
  500: #adadad,
  600: #8f9194,
  700: #616161,
  800: #424242,
  900: #333,
  A100: #fff,
  A200: #eee,
  A400: #999,
  A700: #666,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

/* define material paletts */

$practicemanagement-primary: mat.define-palette(
  $ef-primary-old-palette,
  800,
  600,
  900
);
$practicemanagement-accent: mat.define-palette(
  $ef-accent-old-palette,
  500,
  100,
  700
);
$practicemanagement-warn: mat.define-palette(mat.$red-palette);
$practicemanagement-grey: mat.define-palette($ef-grey-palette);

/* generate css variables */

@include generateColors(primary, $practicemanagement-primary);
@include generateColors(accent, $practicemanagement-accent);
@include generateColors(warn, $practicemanagement-warn);
@include generateColors(ui, $practicemanagement-grey);

/* misc. colors */

:root {
  --ef-alert-error-background-color: #ffe6e6;
  --ef-alert-error-border-color: #c11b00;
  --ef-alert-error-icon-color: #c11b00;
  --ef-alert-info-background-color: #DCF0F9;
  --ef-alert-info-border-color: #9EC3F0;
  --ef-alert-info-icon-color: inherit;
  --ef-alert-warning-background-color: #fcf8e3;
  --ef-alert-warning-border-color: #c09853;
  --ef-alert-warning-icon-color: #c09853;
  --ef-footer-background-color: #023046;
  --ef-footer-text-color: rgba(255, 255, 255, 0.87);
  --ef-header-navigation-background-color: #fff;
  --ef-header-navigation-text-color: var(--mdc-text-button-label-text-color);
  --ef-header-navigation-underline-color: #023046;
  --ef-header-toolbar-background-color: #023046;
  --ef-header-toolbar-highlight-color: #005a96;
  --ef-header-toolbar-text-color: rgba(255, 255, 255, 0.87);
  --ef-link-color: #005a96;
  --ef-menu-item-highlight-color: #005a96;
  --ef-panel-background-color: #fff;
  --ef-table-alternating-color: #f0f0f0;
  --ef-table-border-color: #818285;
  --ef-table-border-width: 1px;
  --ef-table-corner-radius: 0.3rem;
  --ef-table-header-gradient-end: #E7E7E7;
  --ef-table-header-gradient-start: #FAFAFA;
  --ef-table-hover-row-color: #E7E7E7;
}
