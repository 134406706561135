@use "@angular/material" as mat;

@include mat.all-component-typographies();
@include mat.core();

/* Material Theme */

@import "@angular/material/theming";
@import "./foundation/palette";

$pm-body-1: mat.define-typography-level(
  $font-size: 1rem,
  $letter-spacing: normal,
);
$pm-buton: mat.define-typography-level(
  $font-size: 1rem,
  $letter-spacing: normal,
  $line-height: 16px,
);
$practicemanagement-typography: mat.define-typography-config(
  $font-family: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  $body-1: $pm-body-1,
  $button: $pm-buton,
);

$practicemanagement-theme: mat.define-light-theme(
  (
    color: (
      primary: $practicemanagement-primary,
      accent: $practicemanagement-accent,
      warn: $practicemanagement-warn,
    ),
    typography: $practicemanagement-typography,
  )
);

@include mat.all-component-themes($practicemanagement-theme);
@import "./foundation/material";

.mdc-button.mat-alt {
  background-color: var(--ef-ui-100);
}

/* Global Styles */

html,
body {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAFUlEQVQImWNgQAI3b978TyqHgYEBAOC/Dimo4qEvAAAAAElFTkSuQmCC);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0;
}
.color-link, .mat-mdc-button.color-link:not(:disabled) {
  color: var(--ef-link-color);
}
.primary-bg {
  background-color: var(--ef-primary-600);
}
mat-card.notifications {
  mat-card-content {
    ef-alert:not(:last-of-type) {
      border-bottom: 1px solid var(--ef-ui-200);
      margin-bottom: 1em;
      padding-bottom: 1em;
    }
  }
}
mat-snack-bar-container.mat-mdc-snack-bar-container {
  margin-top: 150px;
  &.system-failure {
    --mdc-snackbar-container-color: var(--ef-panel-background-color);
    --mdc-snackbar-supporting-text-color: inherit;
    border: 1px solid var(--ef-alert-error-border-color);
    max-width: 425px;
    [fonticon] {
      color: var(--ef-alert-error-icon-color);
    }
  }
  &.timeout {
    --mdc-snackbar-container-color: var(--ef-panel-background-color);
    --mdc-snackbar-supporting-text-color: inherit;
    border: 1px solid var(--ef-alert-warning-border-color);
    max-width: 400px;
    [fonticon] {
      color: var(--ef-alert-warning-icon-color);
    }
  }
}

/* TODO: Cleanup and organize CSS.  */

/* Bootstrap Overrides. */

a {
  color: var(--ef-link-color);
  &.link-simple {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

/* Material Overrides. */
.container-xl {
  background-color: var(--ef-ui-50);
  padding: 0 15px;
}
.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 8px !important;
}
.mat-mdc-form-field-infix {
  border-top: 0 !important;
  min-height: 40px;
  padding: 0 !important;
}
.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 20px !important;
}
.mdc-text-field--outlined {
  padding-left: max(8px, calc(var(--mdc-shape-small, 4px) + 4px));
  padding-right: max(8px, var(--mdc-shape-small, 4px));
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-26px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.75);
}
.mdc-floating-label--required {
  color: var(--ef-warn-500);
}
.mdc-menu-surface {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0;
  min-height: 43px !important;
  .mdc-list-item {
    height: 32px;
    line-height: 32px;
    min-height: 32px;
    &:hover {
      background-color: var(--ef-menu-item-highlight-color);
      .mdc-list-item__primary-text {
        color: var(--ef-ui-50);
      }
    }
  }
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background: var(--ef-primary-600);
  .mdc-list-item__primary-text {
    color: var(--ef-ui-50);
  }
}
.mat-mdc-option.mat-mdc-option-active {
  background: var(--ef-ui-200);
  .mdc-list-item__primary-text {
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  }
}
.mdc-text-field .mdc-text-field__input {
  margin-top: 4px;
}
.mat-mdc-form-field-hint-wrapper {
  flex-direction: row-reverse;
  .mat-mdc-form-field-hint {
    color: var(--ef-ui-600);
    font-style: italic;
    margin-top: -3px;
  }
}
.mat-mdc-dialog-container .mdc-dialog__title {
  background-color: var(--ef-primary-800);
  border: 2px solid var(--ef-ui-50);
  border-radius: 4px;
  color: var(--ef-ui-50);
  font-size: 24.75px;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 0 !important;
}
.mdc-dialog__title::before {
  height: 34px;
}
.mat-mdc-dialog-actions.mdc-dialog__actions {
  border-top: 1px solid var(--ef-ui-400);
}
.mdc-dialog .mdc-dialog__content {
  padding: 20px 15px;
}
.mat-mdc-form-field-textarea-control {
  resize: none;
}
.mat-mdc-icon-button.mat-mdc-button-base {
  width: 38px;
  height: 38px;
  padding: 7px;
  border-radius: 0 4px 0 4px;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
  border-radius: 0 4px 0 4px;
}
.mat-mdc-menu-content {
  a {
    text-decoration: none !important;
  }
}

ef-header-navigation {
  .mat-mdc-button {
    .mdc-button__label {
      border-bottom: 2px solid transparent;
      padding: 8px 0 6px 0;
    }
    .mat-mdc-button-ripple {
      display: none;
    }
    &:hover {
      .mdc-button__label {
        border-bottom-color: var(--ef-header-navigation-underline-color);
      }
    }
    &:not(:focus):hover {
      .mat-mdc-button-persistent-ripple::before {
        background-color: transparent;
      }
    }
    &[aria-expanded="true"] {
      .mdc-button__label {
        border-bottom-color: var(--ef-header-navigation-underline-color);
      }
    }
  }
}

@import "./material/card";
@import "./material/table";

/* Styles for Design Only */
.design {
  .component {
    align-items: center;
    background-color: var(--ef-ui-100);
    display: flex;
    justify-content: center;
    padding: 1em;
    > div {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;
    }
  }
}
